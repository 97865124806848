@import 'styles/legacy/colors';

@import '../mixins';

.header {
  align-items: center;
  background-color: $color-white;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  box-sizing: border-box;
  display: grid;
  flex-shrink: 0;
  grid-gap: 16px;
  margin-bottom: 8px;
  min-height: 68px;
  padding: 16px var(--dialog-padding-horizontal);
  position: relative;
  z-index: 1;

  &.sticky {
    position: sticky;
    top: 0;
  }
}

.title {
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  padding: 8px 0;

  .withCloseButton & {
    padding-right: 8px;
  }

  .withBackButton & {
    padding-left: 8px;
  }
}

@include dialog-mobile-view {
  .header {
    grid-gap: 12px;
    min-height: 56px;
    padding: 12px var(--dialog-padding-horizontal);
  }

  .title {
    font-size: 16px;
    line-height: 20px;
    padding: 6px 0;

    .withCloseButton & {
      padding-right: 32px;
    }

    .withBackButton & {
      padding-left: 32px;
    }
  }
}
