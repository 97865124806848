@import 'styles/layout';
@import 'styles/legacy/colors';
@import 'styles/input';

.inputWrap {
  background-color: $color-white;
  border-radius: var(--joompro-uikit-input-border-radius, 6px);
  display: inline-block;
  font-weight: 400;
  position: relative;
}

.block {
  display: block;
}

.input {
  @include resetInput;
  border-radius: var(--joompro-uikit-input-border-radius, 6px);
  box-sizing: border-box;
  color: $color-black-main;
  font-size: inherit;
  font-weight: inherit;
  height: 100%;
  line-height: inherit;
  padding: 0 16px 1px;
  position: relative;
  width: 100%;

  &:hover {
    ~ .border {
      border-color: $color-gray-secondary;
    }
  }

  &:focus {
    ~ .border {
      border-color: $color-gray-deep;
    }
  }

  // Error
  .withError & {
    ~ .border {
      border-color: $color-sunshine-main;
    }

    ~ .label {
      color: $color-sunshine-main;
    }
  }

  // Default placeholder
  &::placeholder {
    color: $color-gray-secondary;
  }

  // Placeholder is hidden when label is defined
  .withLabel &:not(:focus)::placeholder {
    color: transparent;
  }
}

.border {
  @include stretchAbsolute;

  border: 1px solid $color-gray-secondary-light;
  border-radius: var(--joompro-uikit-input-border-radius, 6px);
  pointer-events: none;
  transition: 0.05s ease-in;
}

.label {
  background-color: var(--joompro-core-color-white);
  box-sizing: border-box;
  color: $color-gray-secondary;
  font-size: inherit;
  font-weight: inherit;
  left: 12px;
  line-height: inherit;
  max-width: calc(100% - 24px);
  overflow: hidden;
  padding: 0 4px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  transition:
    0.06s ease-in,
    background-color 0.04s step-end;
  user-select: none;
  white-space: nowrap;

  .input:focus ~ &,
  .notEmpty & {
    background-color: $color-white;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    top: -8px;
  }

  .dark .input:focus ~ &,
  .dark .notEmpty & {
    background-color: var(--joompro-core-color-black-1000);
  }
}

.maskPlaceholder {
  align-items: center;
  display: flex;
  height: 100%;
  padding: 0 16px 1px;
  position: absolute;
  white-space: break-spaces;

  .head {
    color: transparent;
  }

  .tail {
    color: $color-gray-deep;
  }
}

.size-small {
  font-size: $input-font-size;
  height: 32px;
  line-height: $input-line-height;

  .input {
    padding: 0 8px;
  }

  &.empty .label {
    left: 4px;
    max-width: calc(100% - 8px);
    top: 6px;
  }

  .input:focus ~ .label,
  &.notEmpty .label {
    display: none;
  }
}

.size-medium {
  font-size: $input-font-size;
  height: 40px;
  line-height: $input-line-height;

  &.empty .input:not(:focus) ~ .label {
    top: 10px;
  }
}

.size-large {
  font-size: $input-font-size;
  height: 48px;
  line-height: $input-line-height;

  &.empty .input:not(:focus) ~ .label {
    top: 14px;
  }
}

.size-x-large {
  font-size: 16px;
  height: 56px;
  line-height: $input-line-height;

  &.empty .input:not(:focus) ~ .label {
    top: 18px;
  }
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.dark {
  background-color: var(--joompro-core-color-black-1000);
}

.dark .input {
  color: var(--joompro-core-color-text-disabled);
}

.dark .label {
  background-color: var(--joompro-core-color-black-1000);
}

.fullSize {
  width: 100%;
}
