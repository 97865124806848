// If the font-size of an <input> is 16px or larger,
// Safari on iOS will focus into the input normally.
// But as soon as the font-size is 15px or less,
// the viewport will zoom into that input.
// Use at least 16px to avoid such behavior.
// TASK: PRO-305. @ddgrishkin
$input-font-size: 16px;
$input-line-height: 20px;

@mixin resetInput {
  background: none;
  border: none;
  box-shadow: none;
  margin: 0;
  outline: none;
  padding: 0;
}
