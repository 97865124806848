@import 'styles/core/button';

@import '../mixins';

.backButton {
  @include resetButton;

  background: url('~icons/back.svg') no-repeat 50% 50%;
  cursor: pointer;
  display: block;
  height: 24px;
  left: 24px;
  opacity: 0.6;
  pointer-events: all;
  position: absolute;
  top: 24px;
  transition: opacity 0.1s ease;
  width: 24px;

  &:hover {
    opacity: 1;
  }

  @include dialog-mobile-view {
    left: 16px;
    top: 16px;
  }
}
