@import 'styles/legacy/colors';
@import 'styles/core/button';

@import './mixins';

.dialog {
  --dialog-padding-horizontal: 48px;
  --dialog-padding-vertical: 48px;

  box-sizing: border-box;
  display: flex;
  height: 100%;
  left: 0;
  overflow-y: auto;
  position: fixed;
  top: 0;
  width: 100%;
}

.scrim {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.surfaceWrap {
  box-sizing: border-box;
  margin: auto;
  max-width: 100%;
  padding: 32px;
  pointer-events: none;
}

.surface {
  animation: dialog-surfacing-animation 0.15s ease;
  background-color: $color-white;
  border-radius: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  flex-grow: 0; // IE 11
  flex-shrink: 0; // IE 11
  max-height: 100%; // IE 11
  max-width: 100%; // IE 11
  pointer-events: auto;
  position: relative;
  transform-origin: center center;
}

.closeButtonWrap {
  height: 0;
  position: sticky;
  top: 0;
  z-index: 100;
}

.backButtonWrap {
  height: 0;
  position: sticky;
  top: 0;
  z-index: 100;
}

@include dialog-mobile-view {
  .dialog {
    --dialog-padding-horizontal: 16px;
    --dialog-padding-vertical: 16px;

    overflow-y: unset;
  }

  .surfaceWrap {
    display: flex;
    margin-bottom: 0;
    max-height: 100%;
    padding: 12px 0 0;
    width: 100%;

    .fullscreen-mobile & {
      height: 100%;
      padding: 0;
    }
  }

  .surface {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    overflow-y: auto;
    // This is the exception for dialog
    // stylelint-disable-next-line declaration-no-important
    width: 100% !important;

    .fullscreen-mobile & {
      animation: dialog-zoomIn-animation 0.05s ease-out;
      border-radius: 0;
      height: 100%;
    }
  }
}

@keyframes dialog-surfacing-animation {
  from {
    transform: translateY(32px);
  }

  to {
    transform: translateY(0px);
  }
}

@keyframes dialog-zoomIn-animation {
  from {
    opacity: 0.5;
    transform: scale(0.85);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}
