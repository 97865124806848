@import './base';

:root {
  --joompro-core-color-primary-default: var(--joompro-core-color-black-1000);
  --joompro-core-color-primary-hover: var(--joompro-core-color-black-800);
  --joompro-core-color-primary-disabled: hsl(0 0% 96%); // #F5F5F5
  --joompro-core-color-primary-default-invert: var(--joompro-core-color-white);
  --joompro-core-color-primary-hover-invert: hsla(0, 0%, 100%, 0.1); // #FFFFFF 10%
  --joompro-core-color-primary-disabled-invert: hsla(0, 0%, 100%, 0.4); // #FFFFFF 40%
}
