@import 'styles/core/typography';

.figure {
  height: 128px;
  margin-bottom: 24px;
  text-align: center;
}

.image {
  height: 100%;
}

.title {
  @include titleAlternativeH2;
  margin-bottom: 16px;
  text-align: center;
}

.subtitle {
  @include textL;
  text-align: center;
}
