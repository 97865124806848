@import 'styles/legacy/colors';

.email {
  color: $color-ocean-main;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.content {
  display: grid;
  row-gap: 16px;
  text-align: center;
}

.loading {
  text-align: center;
}

.support {
  font-size: 12px;
  line-height: 14px;
}
