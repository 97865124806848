@import 'styles/legacy/colors';
@import 'styles/input';

.input {
  @include resetInput;

  align-content: center;
  border: 1px solid $color-black-alpha16;
  border-radius: 6px;
  box-sizing: border-box;
  color: $color-black-deep;
  display: flex;
  font-size: 24px;
  font-weight: 500;
  height: 48px;
  line-height: 28px;
  padding: 0 16px 1px;
  text-align: center;
  transition: border-color 0.05s ease-in;
  width: 100%;

  &:hover {
    border-color: $color-black-alpha30;
  }

  &:focus {
    border-color: $color-black-alpha60;
  }
}
