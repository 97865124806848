@import 'styles/core/button';

.button {
  @include resetButton;

  align-items: center;
  border: 2px solid transparent;
  box-sizing: border-box;
  cursor: pointer;
  display: inline-grid;
  font-family: var(--joompro-core-font-signal);
  grid-template: 'content' auto / 1fr;
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: background-color 0.07s ease-in;

  &:disabled,
  &.disabled {
    cursor: default;
  }

  &:focus-visible {
    outline: 2px solid var(--button-outline-color, var(--joompro-core-color-accent-default));
  }
}

.content {
  align-items: center;
  display: flex;
  grid-area: content;
  margin: 0 auto;
  position: relative;
  white-space: nowrap;
}

.content-hidden {
  visibility: hidden;
}

.fullIcon {
  background-color: var(--button-label-color);
  display: inline-block;
  font-size: 24px;
  grid-area: content;
  height: 24px;
  margin: 0 auto;
  mask: url('~icons/loader.svg') no-repeat center / contain;
  width: 24px;
}

.loading {
  mask: url('~icons/loader.svg') no-repeat center / contain;
}

.showCheck {
  mask: url('~icons/check-bold.svg') no-repeat center / contain;
}

.shape-classic {
  border-radius: 12px;

  &.icon-only {
    border: 0;

    .label {
      display: none;
    }

    .icon {
      margin: 0;
    }
  }
}

.shape-round {
  border-radius: 100px;

  &.icon-only {
    border: 0;

    .label {
      display: none;
    }

    .icon {
      margin: 0;
    }
  }
}

.shape-circle {
  --button-padding: 0;
  border-radius: 50%;
  overflow: hidden;

  &.size-small {
    width: 32px;
  }

  &.size-medium {
    width: 40px;
  }

  &.size-large {
    width: 48px;
  }

  &.size-x-large {
    width: 56px;
  }

  &.with-icon {
    .label {
      display: none;
    }

    .icon {
      margin: 0;
    }
  }
}

.size-small {
  height: 32px;
  padding: 0 var(--button-padding, 12px);
  @include subTitleM;

  .fullIcon,
  .icon {
    font-size: 20px;
    height: 20px;
    width: 20px;
  }

  .label {
    margin-top: -1px;
  }
}

.size-medium {
  height: 40px;
  padding: 0 var(--button-padding, 16px);
  @include subTitleL;
}

.size-large {
  --button-icon-outside-margin: -8px;
  height: 48px;
  padding: 0 var(--button-padding, 24px);
  @include subTitleL;
}

.size-x-large {
  --button-icon-outside-margin: -16px;
  height: 56px;
  padding: 0 var(--button-padding, 32px);
  @include titleAlternativeH3;
}

.disabled {
  cursor: default;
  pointer-events: none;
}

.color-black {
  --button-label-color: var(--joompro-core-color-text-main-invert);
  background: var(--joompro-core-color-primary-default);
  color: var(--button-label-color);

  &:hover,
  &:active {
    background: var(--joompro-core-color-primary-hover);
  }

  &:disabled,
  &.disabled {
    --button-label-color: var(--joompro-core-color-text-disabled);
    background: var(--joompro-core-color-primary-disabled);
  }
}

.color-white {
  --button-label-color: var(--joompro-core-color-text-main);
  background: var(--joompro-core-color-primary-default-invert);
  color: var(--button-label-color);

  &:hover,
  &:active {
    background: var(--joompro-core-color-primary-default-invert);
  }

  &:disabled,
  &.disabled {
    --button-label-color: var(--joompro-core-color-text-disabled);
    background: var(--joompro-core-color-primary-disabled-invert);
  }
}

.color-gray {
  --button-label-color: var(--joompro-core-color-text-main);
  background: var(--joompro-core-color-secondary-default);
  color: var(--button-label-color);

  &:hover {
    background: var(--joompro-core-color-secondary-hover);
  }

  &:disabled,
  &.disabled {
    --button-label-color: var(--joompro-core-color-text-disabled);
    background: var(--joompro-core-color-secondary-disabled);
  }
}

.color-accent {
  --button-label-color: var(--joompro-core-color-text-main-invert);
  --button-outline-color: var(--joompro-core-color-primary-default);
  background: var(--joompro-core-color-accent-default);
  color: var(--button-label-color);

  &:hover {
    background: var(--joompro-core-color-accent-hover);
  }

  &:disabled,
  &.disabled {
    --button-label-color: var(--joompro-core-color-text-disabled);
    background: var(--joompro-core-color-primary-disabled);
  }
}

.color-outline-white {
  --button-label-color: var(--joompro-core-color-text-main-invert);
  background: transparent;
  border-color: var(--joompro-core-color-primary-default-invert);
  color: var(--button-label-color);

  &:hover {
    background: var(--joompro-core-color-primary-hover-invert);
  }

  &:disabled,
  &.disabled {
    --button-label-color: var(--joompro-core-color-text-disabled-invert);
    background: transparent;
    border-color: var(--joompro-core-color-primary-disabled-invert);
  }
}

.color-outline-black {
  --button-label-color: var(--joompro-core-color-text-main);
  background: transparent;
  border-color: var(--joompro-core-color-primary-default);
  color: var(--button-label-color);

  &:hover {
    background: var(--joompro-core-color-secondary-hover-outline);
  }

  &:disabled,
  &.disabled {
    --button-label-color: var(--joompro-core-color-text-disabled);
    background: transparent;
    border-color: var(--joompro-core-color-secondary-disabled-outline);
  }
}

.color-transparent {
  --button-label-color: var(--joompro-core-color-text-main-invert);
  background: var(--joompro-core-color-support-fade-30);
  color: var(--button-label-color);

  &:hover {
    background: var(--joompro-core-color-support-fade-60);
  }

  &:disabled,
  &.disabled {
    --button-label-color: var(--joompro-core-color-support-fade-50-inverted);
    background: transparent;
  }
}

.icon {
  align-items: center;
  display: inline-block;
  display: flex;
  font-size: 24px;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.icon-left {
  margin-left: var(--button-icon-outside-margin);
  margin-right: 8px;
}

.icon-right {
  margin-left: 8px;
  margin-right: var(--button-icon-outside-margin);
}
