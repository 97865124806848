@import 'styles/core/button';
@import 'styles/legacy/colors';

.tabs {
  display: flex;
}

.tab {
  @include resetButton;

  border-bottom: 2px solid $color-gray-background-secondary;
  border-top: 2px solid transparent;
  box-sizing: border-box;
  color: $color-gray-deep;
  cursor: pointer;
  flex-basis: 100%;
  flex-grow: 1;
  font-size: inherit;
  font-weight: inherit;
  height: inherit;
  line-height: inherit;
  padding: 0 8px;
  text-align: center;
  transition: 0.07s ease-in;

  &:hover {
    color: $color-black-main;
  }

  &.active {
    border-bottom-color: $color-black-main;
    color: $color-black-main;
  }
}

.size-large {
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  line-height: 20px;
}
