@import 'styles/legacy/colors';

.loading {
  display: inline-block;
  position: relative;
  vertical-align: -0.166em;
  width: 4em;
}

.color-inherit {
  color: inherit;
}

.color-black {
  color: $color-black-main;
}

.color-gray {
  color: $color-gray-secondary;
}

.inner {
  box-sizing: border-box;
  display: block;
  padding-top: 25%;
  position: relative;
}

.loading,
.inner {
  &:after,
  &:before {
    animation: dotPulse 0.4s ease-in infinite alternate;
    background-color: currentColor;
    border-radius: 50%;
    content: '';
    display: block;
    left: 6%;
    opacity: 0.25;
    padding-top: 13%;
    position: absolute;
    top: 25%;
    transform: scale(0.5);
    transform-origin: center;
    width: 13%;
  }
}

.loading:after {
  animation-delay: 0.1s;
  left: 31%;
}

.inner:before {
  animation-delay: 0.2s;
  left: 56%;
}

.inner:after {
  animation-delay: 0.3s;
  left: 81%;
}

@keyframes dotPulse {
  0% {
    opacity: 0.25;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1.25);
  }
}
