.step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
