@import 'styles/core/typography';

.label {
  @include textL;
  margin-bottom: 16px;
}

.field,
.actions {
  display: grid;
}

.field {
  margin-bottom: 24px;
}

.error {
  color: var(--joompro-core-color-input-error);
  display: block;
  margin-top: 16px;
  text-align: center;
}
