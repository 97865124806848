@import 'styles/core/typography';

.MuiSelect-icon {
  color: var(--joompro-core-color-icon-dark-gray);
  top: 17px;
}

.MuiFormControl-root {
  .MuiFormLabel-root {
    color: var(--joompro-core-color-input-default);
  }
  .MuiInputLabel-animated {
    color: var(--joompro-core-color-text-secondary);
  }
  .Mui-error {
    color: var(--joompro-core-color-input-error);

    .MuiOutlinedInput-notchedOutline {
      border-color: var(--joompro-core-color-input-error);
    }
  }
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--joompro-core-color-input-default);
  border-radius: 12px;

  &:hover {
    border-color: var(--joompro-core-color-input-hover);
  }
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--joompro-core-color-input-hover);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--joompro-core-color-input-focus);
  border-width: 1px;
}

.MuiCircularProgress-circle {
  color: var(--joompro-core-color-icon-dark-gray);
}

.MuiListItemButton-root {
  &.MuiListItemButton-root {
    border-radius: 12px;

    &.Mui-selected {
      background-color: var(--joompro-core-color-gray-200);

      &:hover {
        background-color: var(--joompro-core-color-gray-200);
      }
    }

    &:hover {
      background-color: var(--joompro-core-color-gray-100);
    }

    .MuiTouchRipple-root {
      .MuiTouchRipple-child {
        background-color: var(--joompro-core-color-gray-600);
      }
    }
  }

  .MuiTypography-root {
    @include textL;
  }
}
