@import 'styles/legacy/colors';

.container {
  display: grid;
}

.options {
  background: $color-white;
  border-radius: 4px;
  box-shadow: 0 4px 12px $color-black-alpha10;
  min-width: 100%;
  padding: 8px 0;
  position: absolute;
}
