.link {
  text-decoration: none;
}

.underlined .content {
  text-decoration: underline;
}

.iconLeft {
  margin-right: 8px;
}

.themeDefault .iconLeft {
  color: var(--joompro-core-color-text-main-accent);
}

.themeDefault:hover .content,
.themeDefault:focus-visible .content {
  color: var(--joompro-core-color-text-main-accent);
}
.themeDefault:active .content {
  color: var(--joompro-core-color-text-main-accent);
}

.themeInverted {
  color: var(--joompro-core-color-text-main-invert);
}

.themeInverted .iconLeft {
  color: var(--joompro-core-color-text-main-invert);
}

.themeInverted:hover .iconLeft,
.themeInverted:focus-visible .iconLeft,
.themeInverted:hover .content,
.themeInverted:focus-visible .content {
  color: var(--joompro-core-color-text-secondary-invert);
}

.themeRed {
  color: var(--joompro-core-color-text-main-accent);
}

.themeRed .iconLeft {
  color: var(--joompro-core-color-text-main-accent);
}

.themeRed:hover .iconLeft,
.themeRed:focus-visible .iconLeft,
.themeRed:hover .content,
.themeRed:focus-visible .content {
  color: var(--joompro-core-color-accent-hover);
}

.themeRed:active .content {
  color: var(--joompro-core-color-text-main-accent);
}
