@import './base';

:root {
  --joompro-core-color-icon-accent-black: var(--joompro-core-color-black-1000);
  --joompro-core-color-icon-inverted: var(--joompro-core-color-white);
  --joompro-core-color-icon-dark-gray: hsla(0 0% 60%); // #999999
  --joompro-core-color-icon-gray: var(--joompro-core-color-gray-800);
  --joompro-core-color-icon-accent-red: var(--joompro-core-color-red);
  --joompro-core-color-icon-inverted-disabled: hsla(0, 0%, 100%, 0.4); // #FFFFFF 40%
}
