@import 'uikit/Dialog/mixins';

.form {
  display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr;
  margin-top: 16px;
}

.actions {
  display: grid;
  padding-top: 12px;
}
