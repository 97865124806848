.layout {
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: fixed;
  right: 0;
}

.container {
  position: relative;
}

.toaster {
  bottom: 40px;
  max-width: 416px;
  position: absolute;
  right: 0;
  width: 100%;
}
