@import 'styles/core/typography';
@import 'uikit/Dialog/mixins';

.header {
  align-items: center;
  display: flex;
  flex-direction: column;

  @include dialog-mobile-view() {
    padding-top: 32px;
  }
}

.image {
  background: none no-repeat center / contain;
  box-sizing: border-box;
  height: 120px;
  margin-bottom: 24px;
  width: 100%;

  @include dialog-mobile-view() {
    height: 100px;
  }
}

.title {
  @include titleAlternativeH2;

  color: var(--joompro-core-color-text-title);
  text-align: center;

  @include dialog-mobile-view() {
    @include titleAlternativeH3;
  }
}

.subtitle {
  @include textL;

  color: var(--joompro-core-color-text-main);
  margin-top: 16px;
  text-align: center;
}
