@mixin titleH1 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 28px;
  font-weight: 400;
  line-height: 34px;
}

@mixin titleH2 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
}

@mixin titleAlternativeH2 {
  font-family: var(--joompro-core-font-signal);
  font-size: 22px;
  font-weight: 400;
  line-height: 32px;
}

@mixin titleH3 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

@mixin titleAlternativeH3 {
  font-family: var(--joompro-core-font-signal);
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

@mixin titleH4 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

@mixin titleH5 {
  font-family: var(--joompro-core-font-joompro);
  font-size: 16px;
  font-weight: 400;
  line-height: 18px;
}

@mixin subTitleL {
  font-family: var(--joompro-core-font-signal);
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
}

@mixin subTitleM {
  font-family: var(--joompro-core-font-signal);
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
}

@mixin textXL {
  font-family: var(--joompro-core-font-signal);
  font-size: 18px;
  font-weight: 200;
  line-height: 22px;
}

@mixin textL {
  font-family: var(--joompro-core-font-signal);
  font-size: 16px;
  font-weight: 200;
  line-height: 20px;
}

@mixin textM {
  font-family: var(--joompro-core-font-signal);
  font-size: 14px;
  font-weight: 200;
  line-height: 17px;
}

@mixin textS {
  font-family: var(--joompro-core-font-signal);
  font-size: 12px;
  font-weight: 200;
  line-height: 15px;
}

@mixin accentL {
  font-family: var(--joompro-core-font-signal);
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
}

@mixin accentM {
  font-family: var(--joompro-core-font-signal);
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}

@mixin accentS {
  font-family: var(--joompro-core-font-signal);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
}

@mixin lineL {
  @include textL;
  text-decoration: underline;
  text-decoration-thickness: from-font;
  text-underline-offset: 2px;
}

@mixin lineM {
  @include textM;
  text-decoration: underline;
  text-decoration-thickness: from-font;
  text-underline-offset: 2px;
}

@mixin lineS {
  @include textS;
  text-decoration: underline;
  text-decoration-thickness: from-font;
  text-underline-offset: 1px;
}
