@import './base';

:root {
  --joompro-core-color-secondary-default: var(--joompro-core-color-gray-200);
  --joompro-core-color-secondary-hover: hsla(0 0% 90%); // #E5E5E5
  --joompro-core-color-secondary-disabled: hsl(0 0% 96%); // #F5F5F5
  --joompro-core-color-secondary-default-outline: var(--joompro-core-color-black-1000);
  --joompro-core-color-secondary-hover-outline: hsl(0 0% 96%); // #F5F5F5
  --joompro-core-color-secondary-disabled-outline: hsl(0 0% 96%); // #F5F5F5
}
