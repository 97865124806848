@import './legacy/colors';
@import './core/button';

$colorSet: (
  'black': (
    'text': $color-white,
    'text-active': $color-white,
    'default': $color-black-deep,
    'hover': $color-black-deep,
    'active': $color-black-secondary,
  ),
  'gray': (
    'text': $color-black-main,
    'text-active': $color-black-main,
    'default': $color-gray-background,
    'hover': $color-gray-secondary-light,
    'active': $color-gray-secondary,
  ),
  'ghost': (
    'text': $color-white,
    'text-active': $color-white,
    'default': $color-white-alpha10,
    'hover': $color-white-alpha10,
    'active': $color-white-alpha30,
  ),
  'white': (
    'text': $color-black-main,
    'text-active': $color-black-secondary,
    'default': $color-white,
    'hover': $color-white,
    'active': $color-white,
  ),
  'ocean': (
    'text': $color-white,
    'text-active': $color-white,
    'default': $color-ocean-main,
    'hover': $color-ocean-secondary,
    'active': $color-ocean-deep,
  ),
);

@mixin buttonSolidColor($name) {
  $colors: map-get($colorSet, $name);

  background-color: map-get($colors, 'default');
  color: map-get($colors, 'text');

  &:hover,
  &:focus {
    background-color: map-get($colors, 'hover');
  }

  &:active {
    background-color: map-get($colors, 'active');
    color: map-get($colors, 'text-active');
  }

  &:disabled {
    background-color: $color-gray-secondary;
    color: $color-white;
    cursor: default;
  }
}

@mixin buttonInsetBoxShadow($color) {
  box-shadow: inset 0 0 0 2px $color;
}

@mixin buttonOutlinedColor($name) {
  $colors: map-get($colorSet, $name);

  @include buttonInsetBoxShadow(map-get($colors, 'default'));
  background: $color-white;
  color: map-get($colors, 'default');

  &:hover,
  &:focus {
    @include buttonInsetBoxShadow(map-get($colors, 'hover'));
  }

  &:active {
    @include buttonInsetBoxShadow(map-get($colors, 'active'));
  }

  &:disabled {
    @include buttonInsetBoxShadow($color-gray-secondary);
    color: $color-gray-secondary;
    cursor: default;
  }
}

$sizeSet: (
  'small': (
    'height': 32px,
    'font-size': 14px,
    'font-weight': 500,
    'line-height': 16px,
    'shapePadding': (
      'classic': 0 8px,
      'outlined': 0 8px,
      'round': 0 16px,
      'circle': 0,
    ),
  ),
  'medium': (
    'height': 40px,
    'font-size': 16px,
    'font-weight': 500,
    'line-height': 20px,
    'shapePadding': (
      'classic': 0 16px,
      'outlined': 0 16px,
      'round': 0 16px,
      'circle': 0,
    ),
  ),
  'large': (
    'height': 48px,
    'font-size': 16px,
    'font-weight': 500,
    'line-height': 20px,
    'shapePadding': (
      'classic': 0 24px,
      'outlined': 0 24px,
      'round': 0 24px,
      'circle': 0,
    ),
  ),
  'x-large': (
    'height': 56px,
    'font-size': 18px,
    'font-weight': 500,
    'line-height': 22px,
    'shapePadding': (
      'classic': 0 32px,
      'outlined': 0 32px,
      'round': 0 32px,
      'circle': 0,
    ),
  ),
);

@mixin buttonSize($size, $shapes: 'classic') {
  $props: map-get($sizeSet, $size);
  $height: map-get($props, 'height');

  box-sizing: border-box;
  font-size: map-get($props, 'font-size');
  font-weight: map-get($props, 'font-weight');
  height: $height;
  line-height: map-get($props, 'line-height');

  @each $shape in $shapes {
    $padding: map-get(map-get($props, 'shapePadding'), $shape);

    &.shape-#{$shape} {
      padding: $padding;

      @if $shape == 'circle' {
        width: $height;
      }
    }
  }
}
