@import './base';

:root {
  --joompro-core-color-text-title: var(--joompro-core-color-black-1100);
  --joompro-core-color-text-main: var(--joompro-core-color-black-1000);
  --joompro-core-color-text-secondary: hsl(0 0% 48%); // #7A7A7A
  --joompro-core-color-text-disabled: var(--joompro-core-color-gray-800);
  --joompro-core-color-text-title-invert: var(--joompro-core-color-white);
  --joompro-core-color-text-main-invert: var(--joompro-core-color-white);
  --joompro-core-color-text-secondary-invert: hsla(0, 0%, 100%, 0.8); // #FFFFFF 80%
  --joompro-core-color-text-disabled-invert: hsla(0, 0%, 100%, 0.3); // #FFFFFF 30%
  --joompro-core-color-text-main-accent: var(--joompro-core-color-red);
  --joompro-core-color-text-states-selected: hsla(0, 0%, 0%, 0.08);
}
