@import 'uikit/Dialog/mixins';

.form {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  margin-top: 20px;

  @include dialog-mobile-view() {
    margin-top: 24px;
  }
}

.policy {
  margin-top: 16px;
}
