@import 'styles/legacy/colors';
@import 'styles/core/button';

.button {
  @include resetButton;

  color: $color-ocean-main;
  cursor: pointer;
  font-size: 16px;
  line-height: 20px;
}

.text {
  color: $color-black-secondary;
  font-size: 16px;
  line-height: 20px;
}
