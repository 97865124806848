@import 'styles/hack';

$radio-size: 24px;
$radio-border-size: 18px;
$radio-item-size: 8px;

.container {
  @include fixFlexChildWidth;

  --radio-button-color: var(--joompro-core-color-gray-800);

  align-items: center;
  cursor: default;
  display: inline-flex;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.label {
  margin-left: 4px;
}

.input {
  align-items: center;
  appearance: none;
  color: var(--radio-button-color);
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  height: $radio-size;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;
  width: $radio-size;

  &:before,
  &:after {
    border-radius: 50%;
    box-sizing: border-box;
    content: '';
    display: block;
    position: absolute;
    transform-origin: center;
  }

  // border
  &:before {
    border: 2px solid currentColor;
    height: $radio-border-size;
    transition: color 0.1s ease;
    width: $radio-border-size;
  }

  // dot
  &:after {
    background: currentColor;
    height: $radio-item-size;
    opacity: 0;
    transform: scale(0.3);
    transition: 0.15s ease;
    width: $radio-item-size;
  }
}

.enabled {
  cursor: pointer;

  &:hover .input,
  .input:focus,
  .input:checked,
  &.checked .input {
    --radio-button-color: var(--joompro-core-color-black-800);
  }

  &.checked .input:after,
  .input:checked:after {
    opacity: 1;
    transform: scale(1);
  }
}

.disabled.gray {
  .input {
    --radio-button-color: var(--joompro-core-color-gray-600);
    cursor: default;
  }

  .input:after {
    background-color: var(--joompro-core-color-gray-100);
    height: 14px;
    opacity: 1;
    transform: scale(1);
    width: 14px;
  }
}
