@import 'styles/legacy/colors';

$image-size: 120px;
$hint-image-size: 40px;

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  user-select: none;
}

.images {
  position: relative;
}

.image {
  height: $image-size;
  width: $image-size;
}

.hintImage {
  height: $hint-image-size;
  position: absolute;
  right: 0;
  top: 0;
  width: $hint-image-size;
}

.title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;

  .images + & {
    margin-top: 16px;
  }
}

.subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;

  .images + & {
    margin-top: 16px;
  }

  .title + & {
    margin-top: 8px;
  }
}

.tabs {
  margin-top: 16px;
}

.fields {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  margin-top: 24px;
}

.actions {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  margin-top: 24px;
}

.extraContent {
  font-size: 14px;
  line-height: 16px;
  margin-top: 16px;

  &.leftAlign {
    text-align: left;
  }
  &.centerAlign {
    text-align: center;
  }
  &.rightAlign {
    text-align: right;
  }
}

.footer {
  margin-top: 24px;
}

.error {
  color: $color-sunshine-main;
  font-size: 14px;
  line-height: 16px;
  margin-top: 16px;
  text-align: center;
}
