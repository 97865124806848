@import 'typography';

@mixin resetButton($inheritFont: false) {
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
  margin: 0;
  outline: none;
  padding: 0;
  user-select: none;

  @if ($inheritFont) {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
  }
}
