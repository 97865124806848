$breakpoints: (
  'phone': (
    null,
    599px,
  ),
  'tablet': (
    600px,
    1279px,
  ),
  'tablet-small': (
    600px,
    767px,
  ),
  'tablet-medium': (
    768px,
    1023px,
  ),
  'tablet-large': (
    1024px,
    1279px,
  ),
  'desktop': (
    1280px,
    null,
  ),
);

@function get-target-range($target) {
  $ranges: $breakpoints;

  @if not map-has-key($ranges, $target) {
    @error 'Invalid range name! Choose one of #{map-keys($ranges)}';
  }

  @return map-get($ranges, $target);
}

@function media-range-condition($range) {
  $min: nth($range, 1);
  $max: nth($range, 2);
  $result: '';

  @if $min != null and $max != null {
    $result: '(min-width: #{$min}) and (max-width: #{$max})';
  } @else if $min != null {
    $result: '(min-width: #{$min})';
  } @else if $max != null {
    $result: '(max-width: #{$max})';
  }

  @return $result;
}

@mixin media-range($minWidth: null, $maxWidth: null) {
  $condition: media-range-condition(($minWidth, $maxWidth));

  @media #{$condition} {
    @content;
  }
}

@mixin media-targets($targets...) {
  $conditions: ();

  @each $target in $targets {
    $range: get-target-range($target);
    $conditions: append($conditions, media-range-condition($range), comma);
  }

  @media #{$conditions} {
    @content;
  }
}

@mixin media-max-target($target) {
  $range: get-target-range($target);
  $max: nth($range, 2);
  @include media-range(null, $max) {
    @content;
  }
}

@mixin media-min-target($target) {
  $range: get-target-range($target);
  $min: nth($range, 1);
  @include media-range($min, null) {
    @content;
  }
}

@mixin stretchAbsolute {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}
