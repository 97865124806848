@import 'styles/core/button';
@import 'styles/legacy/colors';

.option {
  @include resetButton();

  box-sizing: border-box;
  cursor: pointer;
  display: block;
  font-size: 14px;
  line-height: 16px;
  min-height: 32px;
  padding: 8px 16px;
  text-align: left;
  width: 100%;

  &:hover,
  &:focus,
  &.selected {
    background-color: $color-gray-background-light;
  }
}

.size-small {
  padding: 8px;
}
