@import 'styles/core/button';
@import 'styles/legacy/colors';

.toast {
  animation: toaster-opening 0.2s ease-in-out;
  background-color: $color-gray-background-light;
  border-radius: 8px;
  box-shadow: 0 4px 8px $color-black-alpha16;
  box-sizing: border-box;
  color: $color-black-main;
  font-size: 14px;
  padding: 16px;
  pointer-events: auto;
  position: relative;
  width: 416px;
}

.title {
  font-weight: 500;
  line-height: 16px;
  margin-bottom: 12px;
  padding-right: 20px;
}

.content {
  line-height: 18px;
}

.close {
  @include resetButton();

  background-color: $color-black-main;
  cursor: pointer;
  height: 16px;
  mask: url('~icons/close-circle-filled.svg') no-repeat center / 16px;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 16px;
}

@keyframes toaster-opening {
  from {
    opacity: 0;
    transform: scale(0.9) translateY(20px);
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
