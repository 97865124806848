@import 'core/fonts';
@import 'core/colors';
@import 'core/typography/variables';
@import 'legacy/colors';
@import 'mui';

@include fontJoomPro();
@include fontSignal();

html,
body {
  color: $color-black-main;
  font-weight: 400;
  margin: 0;
  min-width: 360px;
  padding: 0;
  scroll-behavior: smooth;
}

button,
body,
input,
textarea {
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
}

a {
  color: currentColor;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

b,
strong {
  font-weight: 500;
}
