@import 'styles/legacy/colors';

.modalOverlay {
  position: relative;

  &.active {
    z-index: 200;
  }
}

.scrim {
  animation: scrimShowing 0.15s ease-out forwards;
  background-color: $color-black-alpha60;
  bottom: 0;
  display: none;
  left: 0;
  outline: none;
  position: fixed;
  right: 0;
  top: 0;

  &.active {
    display: block;
  }

  &.transparent {
    background-color: transparent;
  }
}

@keyframes scrimShowing {
  0% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}
