@import 'styles/legacy/colors';

.actions {
  align-items: center;
  background-color: inherit;
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  box-sizing: border-box;
  display: grid;
  margin: 8px var(--dialog-padding-horizontal) var(--dialog-padding-vertical);
  position: relative;

  &.sticky {
    position: sticky;
  }
}
