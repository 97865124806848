@import 'styles/core/button';
@import 'styles/legacy/colors';

.subAction {
  @include resetButton;

  color: $color-black-deep;
  cursor: pointer;
  display: inline-block;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}
