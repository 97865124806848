@mixin fontJoomPro() {
  @font-face {
    font-display: block;
    font-family: 'Joompro';
    font-style: normal;
    font-weight: 100 900;
    src:
      url('~fonts/joompro.woff2') format('woff2'),
      url('~fonts/joompro.woff') format('woff');
  }
}

@mixin fontSignal() {
  @font-face {
    font-display: block;
    font-family: 'Signal';
    font-style: normal;
    font-weight: 100 200;
    src:
      url('~fonts/signal-extra-light.woff2') format('woff2'),
      url('~fonts/signal-extra-light.woff') format('woff');
  }

  @font-face {
    font-display: block;
    font-family: 'Signal';
    font-style: normal;
    font-weight: 300;
    src:
      url('~fonts/signal-light.woff2') format('woff2'),
      url('~fonts/signal-light.woff') format('woff');
  }

  @font-face {
    font-display: block;
    font-family: 'Signal';
    font-style: normal;
    font-weight: 400 500;
    src:
      url('~fonts/signal-regular.woff2') format('woff2'),
      url('~fonts/signal-regular.woff') format('woff');
  }

  @font-face {
    font-display: block;
    font-family: 'Signal';
    font-style: normal;
    font-weight: 600 900;
    src:
      url('~fonts/signal-bold.woff2') format('woff2'),
      url('~fonts/signal-bold.woff') format('woff');
  }
}
