.checkbox {
  align-items: center;
  appearance: none;
  cursor: pointer;
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  margin: 0;
  outline: none;
  padding: 0;
  position: relative;

  &:after,
  &:before {
    border-radius: 3px;
    box-sizing: border-box;
    content: '';
    display: block;
    position: absolute;
  }

  &:before {
    background-color: var(--joompro-core-color-white);
    border: 1px solid var(--joompro-core-color-input-default);
    transition: 0.1s ease;
  }

  &:after {
    background-color: var(--joompro-core-color-white);
    mask: url('./icons/tick.svg') center / 100% no-repeat;
  }

  &:hover,
  &:focus {
    &:before {
      border-color: var(--joompro-core-color-input-focus);
    }
  }

  &:checked {
    &:before {
      border-color: var(--joompro-core-color-input-focus);
    }
  }

  &:disabled {
    cursor: default;
    pointer-events: none;

    &:before {
      background-color: var(--joompro-core-color-gray-100);
      border-color: var(--joompro-core-color-input-default);
    }

    &:after {
      background-color: var(--joompro-core-color-gray-100);
    }

    &:hover,
    &:focus {
      &:before {
        border-color: var(--joompro-core-color-input-default);
      }
    }

    &:checked {
      &:before {
        border-color: var(--joompro-core-color-gray-100);
      }

      &:after {
        background-color: var(--joompro-core-color-input-default);
      }
    }
  }
}

.size-small {
  height: 16px;
  vertical-align: -0.17em;
  width: 16px;

  &:after,
  &:before {
    height: 16px;
    width: 16px;
  }

  &:before {
    border-width: 1px;
  }

  &:checked {
    &:before {
      border-width: 8px;
    }
  }
}

.size-medium {
  height: 24px;
  vertical-align: -0.45em;
  width: 24px;

  &:after,
  &:before {
    height: 18px;
    width: 18px;
  }

  &:before {
    border-width: 1px;
  }

  &:checked {
    &:before {
      border-width: 9px;
    }
  }
}
