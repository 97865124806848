@import 'styles/core/typography';

.image {
  width: 200px;
}

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  user-select: none;
}

.title {
  text-align: center;

  @include titleAlternativeH2;
}

.subtitle {
  text-align: center;

  @include textL;
}
