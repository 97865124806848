@import 'styles/legacy/colors';

.splashContent {
  align-items: center;
  color: $color-black-main;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  text-align: center;
}

.image {
  margin-bottom: 24px;
  margin-top: 8px;
  max-width: 90%;
}

.title {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  margin: 0;
  max-width: 640px;
}

.message {
  font-size: 16px;
  font-weight: 200;
  line-height: 20px;
  margin: 16px 0 0;
  max-width: 640px;
}

.content {
  margin-top: 24px;
}
