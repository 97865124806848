/**
 * $correction - корректировка высоты строки. Нужна, чтобы хвосты у букв не обрезались (на IE). Стоит добавлять тогда,
 *   когда размер шрифта (font-size) незначительно отличается от высоты строки (line-height). Суть проблемы: разные
 *   базовые шрифты на разных платформах, они хоть и похожи внешне, но они отличаются некоторыми характеристиками
 */
@mixin lineCorrection($correction) {
  margin-bottom: -#{$correction};
  padding-bottom: #{$correction};
}

/**
 * $lines - количество строк
 * $lineHeight - высота строки. Обязательно должна включать в себя единицы измерения: px, em, etc
 * $correction - корректировка высоты строки.
 */
@mixin lineClamp($lines, $lineHeight: 1.2em, $correction: 0) {
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;

  -webkit-line-clamp: $lines;

  line-height: $lineHeight;

  max-height: $lineHeight * $lines;
  overflow: hidden;
  position: relative;
  text-overflow: ellipsis;

  @if $correction != 0 {
    @include lineCorrection($correction);
  }
}

@mixin oneLine($correction: 0) {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @if $correction != 0 {
    @include lineCorrection($correction);
  }
}
