@import 'styles/legacy/colors';
@import 'styles/input';

.wrapper {
  display: inline-block;
  font-weight: 400;
  position: relative;
}

.textarea {
  align-content: center;
  border: none;
  border-radius: 4px;
  box-sizing: border-box;
  color: $color-black-main;
  display: flex;
  font-size: inherit;
  font-weight: inherit;
  height: 100%;
  line-height: inherit;
  outline: none;
  padding: 14px 16px;
  resize: none;
  width: 100%;

  &:hover {
    ~ .border {
      border-color: $color-gray-secondary;
    }
  }

  &:focus {
    ~ .border {
      border-color: $color-gray-deep;
    }
  }

  // Error
  .withError & {
    ~ .border {
      border-color: $color-sunshine-main;
    }

    ~ .label {
      color: $color-sunshine-main;
    }
  }

  // Default placeholder
  &::placeholder {
    color: $color-gray-secondary;
  }

  // Placeholder is hidden when label is defined
  .withLabel &:not(:focus)::placeholder {
    color: transparent;
  }
}

.border {
  border: 1px solid $color-gray-secondary-light;
  border-radius: 6px;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.05s ease-in;
}

.label {
  box-sizing: border-box;
  color: $color-gray-secondary;
  font-size: inherit;
  font-weight: inherit;
  left: 12px;
  line-height: inherit;
  max-width: calc(100% - 24px);
  overflow: hidden;
  padding: 0 4px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  top: 12px;
  transition:
    0.06s ease-in,
    background-color 0.04s step-end;
  user-select: none;
  white-space: nowrap;

  .textarea:focus ~ &,
  .notEmpty & {
    background-color: $color-white;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    top: -8px;
  }
}

// Sizes
// Small
.size-small {
  font-size: $input-font-size;
  line-height: $input-line-height;

  .textarea {
    padding: 8px;
  }

  &.empty .label {
    left: 4px;
    max-width: calc(100% - 8px);
    top: 8px;
  }

  .textarea:focus ~ .label,
  &.notEmpty .label {
    display: none;
  }
}

// Medium
.size-medium {
  font-size: $input-font-size;
  line-height: $input-line-height;

  &.empty .input:not(:focus) ~ .label {
    top: 10px;
  }
}

// Large
.size-large {
  font-size: $input-font-size;
  line-height: $input-line-height;

  .textarea {
    padding: 14px 16px;
  }

  &.empty .textarea:not(:focus) ~ .label {
    top: 14px;
  }
}

// Extra Large
.size-x-large {
  font-size: $input-font-size;
  line-height: $input-line-height;

  .textarea {
    padding: 18px 16px;
  }

  &.empty .textarea:not(:focus) ~ .label {
    top: 18px;
  }
}
