@import 'styles/core/typography';

.label {
  @include subTitleL;
}

.root {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
