$color-transparent: rgba(255, 255, 255, 0);

$color-black-deep: #060606;
$color-black-main: #2f2f2f;
$color-black-secondary: #666666;

$color-gray-deep: #999999;
$color-gray-secondary: #cccccc;
$color-gray-secondary-light: #e5e5e5;
$color-gray-background: #f2f2f2;
$color-gray-background-secondary: #f5f5f5;
$color-gray-background-light: #f8f8f8;

$color-black-alpha60: rgba($color-black-deep, 0.6);
$color-black-alpha30: rgba($color-black-deep, 0.3);
$color-black-alpha16: rgba($color-black-deep, 0.16);
$color-black-alpha12: rgba($color-black-deep, 0.12);
$color-black-alpha10: rgba($color-black-deep, 0.1);
$color-black-alpha06: rgba($color-black-deep, 0.06);
$color-black-alpha05: rgba($color-black-deep, 0.05);
$color-black-alpha03: rgba($color-black-deep, 0.03);

$color-white: #ffffff;
$color-white-alpha60: rgba($color-white, 0.6);
$color-white-alpha30: rgba($color-white, 0.3);
$color-white-alpha10: rgba($color-white, 0.1);

$color-ocean-deep: #3a58ab;
$color-ocean-main: #466cd9;
$color-ocean-secondary: #6282df;
$color-ocean-light: #a3b5ec;

$color-mandarin-main: #ff8947;
$color-mandarin-secondary: #ffa16c;
$color-mandarin-light: #ffd2b9;

$color-sunshine-main: #fa9c00;
$color-sunshine-secondary: #ffb130;
$color-sunshine-light: #fdf5dc;
