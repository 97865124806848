@import 'styles/core/button';
@import 'styles/legacy/colors';

.item {
  @include resetButton;

  cursor: pointer;
  display: block;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  padding: 12px 16px;
  text-align: left;
  width: 100%;

  &.highlight,
  &:active,
  &:hover {
    background-color: $color-gray-background-secondary;
  }
}

.active {
  background-color: $color-gray-background-secondary;
}
