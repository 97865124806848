:root {
  --joompro-core-color-sky: hsl(206 100% 87%); // #BBE1FF
  --joompro-core-color-sky-40: hsl(206 100% 87% / 40%); // #BBE1FF
  --joompro-core-color-sky-200: hsl(208 100% 97%); // #EEF7FF
  --joompro-core-color-sky-1100: hsl(207 100% 77%); // #88CAFF
  --joompro-core-color-box: hsl(33 32% 71%); // #CDB89F
  --joompro-core-color-box-40: hsl(33 32% 71% / 40%); // #CDB89F
  --joompro-core-color-box-200: hsl(33 30% 93%); // #F2EDE7
  --joompro-core-color-box-1100: hsl(32 32% 61%); // #BC9F7D
  --joompro-core-color-red: hsl(14 100% 59%); // #FF6230
  --joompro-core-color-red-40: hsl(14 100% 59% / 40%); // #FF6230
  --joompro-core-color-red-100: hsl(14 100% 96%); // #FFEFEA
  --joompro-core-color-red-1100: hsl(14 100% 55%); // #FF4D17
  --joompro-core-color-white: hsl(0 0% 100%); // #FFFFFF
  --joompro-core-color-black-1100: hsl(0 0% 2%); // #060606
  --joompro-core-color-black-1000: hsl(0 0% 13%); // #212121
  --joompro-core-color-black-800: hsl(0 0% 18%); // #2E2E2E
  --joompro-core-color-gray-1000: hsl(0 0% 35%); // #595959
  --joompro-core-color-gray-800: hsl(0 0% 80%); // #CCCCCC
  --joompro-core-color-gray-600: hsl(0 0% 87%); // #DDDDDD
  --joompro-core-color-gray-200: hsl(0 0% 95%); // #F2F2F2
  --joompro-core-color-gray-100: hsl(0 0% 97%); // #F8F8F8
  --joompro-core-color-mercado-100: hsl(53, 96%, 64%); // #FBE74D
  --joompro-core-color-china-800: hsl(5, 90%, 60%); // #F54B3C
  --joompro-core-color-china-1000: hsl(5, 90%, 48%); // #E81E0C
}
