.contentWrap {
  padding: var(--dialog-padding-vertical) var(--dialog-padding-horizontal);

  &.noTopSpacing {
    padding-top: 0;
  }

  &.noBottomSpacing {
    padding-bottom: 0;
  }
  &.noPadding {
    padding: 0;
  }
}
