@import 'styles/legacy/colors';

.menu {
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 4px 8px $color-black-alpha10;
  box-sizing: border-box;
  flex-direction: column;
  padding: 8px 0;
  position: absolute;

  &[data-popper-placement^='top'] {
    box-shadow: 0 -4px 8px $color-black-alpha10;
  }

  &[data-popper-placement^='bottom'] {
    box-shadow: 0 4px 8px $color-black-alpha10;
  }
}
