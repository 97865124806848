@import 'styles/button';
@import 'uikit/Dialog/mixins';
@import 'styles/core/typography';

.content {
  display: grid;
  grid-gap: 24px;
  grid-template-columns: 1fr;

  @include dialog-mobile-view() {
    padding-top: 32px;
  }
}

.image {
  box-sizing: border-box;
  display: block;
  height: 120px;
  justify-self: center;

  @include dialog-mobile-view() {
    max-height: 100px;
  }
}

.text {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
}

.title {
  @include titleAlternativeH2;

  color: var(--joompro-core-color-text-title);
  text-align: center;

  @include dialog-mobile-view() {
    @include titleAlternativeH3;
  }
}

.message {
  @include textL;

  color: var(--joompro-core-color-text-main);
  text-align: center;
}

.social {
  @include accentM;

  color: var(--joompro-core-color-text-main-invert);
}

.social,
.message {
  @include accentM();

  text-align: center;
  white-space: pre-wrap;
}

.link {
  @include buttonOutlinedColor('black');
  @include buttonSize('large');
  align-items: center;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

.icon {
  background: url('~icons/telegram-logo.svg');
  display: inline-block;
  height: 24px;
  width: 24px;
}

.social {
  margin-bottom: 20px;
}

.footer {
  background-color: var(--joompro-core-color-black-1100);
  border-radius: 32px;
  display: grid;
  padding: 24px;
}
