@import 'styles/core/button';
@import 'styles/legacy/colors';
@import 'styles/text';

.select {
  border-radius: 4px;
  display: inline-block;
  position: relative;
}

.action {
  @include resetButton(true);

  align-items: center;
  border: 1px solid $color-gray-secondary-light;
  border-radius: inherit;
  cursor: pointer;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 0 8px 0 16px;
  width: 100%;

  &:after {
    background-color: $color-gray-deep;
    content: '';
    display: block;
    flex-shrink: 0;
    height: 24px;
    margin-left: 8px;
    mask: url('~icons/chevron-down.svg') no-repeat center / contain;
    pointer-events: none;
    transform-origin: center;
    transition: transform 0.2s ease;
    width: 24px;
  }

  .opened &:after {
    transform: rotate(-180deg);
  }
}

.value {
  @include oneLine();

  color: $color-black-main;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 14px;
  line-height: 16px;
  text-align: left;
}

.label {
  box-sizing: border-box;
  color: $color-gray-secondary;
  font-size: inherit;
  font-weight: inherit;
  left: 12px;
  line-height: inherit;
  max-width: calc(100% - 24px);
  overflow: hidden;
  padding: 0 4px;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  transition:
    0.06s ease-in,
    background-color 0.04s step-end;
  user-select: none;
  white-space: nowrap;

  .notEmpty & {
    background-color: $color-white;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    top: -8px;
  }
}

.items {
  padding: 8px 0;
}

.size-small {
  height: 32px;

  .action {
    padding-left: 8px;
    padding-right: 4px;

    &:after {
      height: 20px;
      width: 20px;
    }
  }

  &.notEmpty > .label {
    display: none;
  }
}

.size-medium {
  height: 40px;

  &.empty > .label {
    top: 12px;
  }
}

.size-large {
  height: 48px;

  .value {
    font-size: 16px;
    line-height: 20px;
  }

  &.empty > .label {
    top: 14px;
  }
}
